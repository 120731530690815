<template>
<!--  <LoginPage v-if="page=='login'"/>-->
  <IndexPage />
</template>

<script>
// import LoginPage from './components/LoginPage.vue'
import IndexPage from './components/IndexPage.vue'
export default {
  name: 'App',
  components: {
    // LoginPage,
    IndexPage
  },
  data(){
    return{
      page:'',
      user:""
    }
  },
  watch(){

  },
  created(){

  },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
}
  body{
    margin: 0 !important;
  }
</style>
