<template>
  <div class="container" v-if="page == 'index'">
    <div class="topBar">
      <span>湖南省智慧儿童一体化平台</span>
      <img style="float: right;right: 0;height: 60%;margin-right: 20px;margin-top: 15px" src="../assets/plogo.png">
/*      <img style="float: right;right: 0;margin-right: 160px;margin-top: 60px;position: absolute;width: 150px;height: 150px" src="../assets/msg1.png">*/
    </div>
    <div class="centerBar">
      <div class="leftBar">
        <div class="menu">
          <UL style="width: 100%">
            <LI :class="scurrpage==item.text?'mactive':''" @click="enterPage(item.url,item.text)"
                style="cursor: pointer;width: 100%;display:flex;height: 50px;line-height: 50px;font-weight:600"
                v-for="(item,index) in menulist" :key="'menu-'+index">
              <div class="menu1" style="width: 5px;height: 100%"></div>
              <div style="width: 100%;text-align: center">{{ item.text }}</div>
            </LI>
          </UL>
        </div>
      </div>
      <div class="contentBar">
        <div class="menuBar">
          <ul>
            <li :class="currpage=='index'?'active':''" @click="enterPage('index','首页')">首页</li>
            <li :class="currpage=='dagl'?'active':''" @click="enterPage('dagl','困境儿童')">档案管理</li>
            <li :class="currpage=='fwgz'?'active':''" @click="enterPage('fwgz','实地走访')">服务跟踪</li>
            <li :class="currpage=='jdgl'?'active':''" @click="enterPage('jdgl','定位查询')">监督管理</li>
            <li :class="currpage=='yjzh'?'active':''" @click="enterPage('yjzh','远程督查')">应急指挥</li>
            <li :class="currpage=='tjfx'?'active':''" @click="enterPage('tjfx','数量统计')">统计分析</li>
            <li :class="currpage=='bgzl'?'active':''" @click="enterPage('bgzl','政策法规')">办公助手</li>
            <li :class="currpage=='xtgl'?'active':''" @click="enterPage('xtgl','机构管理')">系统管理</li>
          </ul>
        </div>
        <div class="beijing">
          <img v-if="currpage=='index'" class="beijingtu" src="../assets/index.jpg">
          <img v-if="currpage=='dagl'" class="beijingtu" src="../assets/dagl.jpg">
          <img v-if="currpage=='fwgz'" class="beijingtu" src="../assets/fwgz.jpg">
          <img v-if="currpage=='jdgl'" class="beijingtu" src="../assets/dwjd.jpg">
          <img v-if="currpage=='yjzh'" class="beijingtu" src="../assets/yjzh.jpg">
          <img v-if="currpage=='tjfx'" class="beijingtu" src="../assets/tjfx.jpg">
          <img v-if="currpage=='bgzl'" class="beijingtu" src="../assets/gztz.jpg">
          <img v-if="currpage=='xtgl'" class="beijingtu" src="../assets/user.jpg">
        </div>
      </div>
    </div>
  </div>
  <div class="container2" v-if="page == 'login'">
    <div class="login-container">
      <div class="login-form">
        <div class="login-logo">
          <img src="../assets/login-logo.png">
        </div>
        <div class="login-username" style="margin-top: 50px">
          <img src="../assets/username.png"><input type="text" v-model="username" placeholder="请输入登录账号">
        </div>
        <div class="login-username">
          <img src="../assets/password.png"><input type="password" v-model="password" placeholder="请输入登录密码">
        </div>
        <div class="login-submit">
          <button @click="login" style="cursor: pointer">登录</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndexPage",
  data() {
    return {
      imgPath: '../assets/index.jpg',
      currpage: 'index',
      smenus: {
        index: [{text: '首页', url: 'index'}],
        dagl: [{text: '困境儿童', url: 'dagl'}, {text: '留守儿童', url: 'dagl'}, {text: '残孤儿童', url: 'dagl'}],
        fwgz: [{text: '实地走访', url: 'fwgz'}, {text: '心理评测', url: 'fwgz'}, {text: '心理辅导', url: 'fwgz'}, {
          text: '帮困扶助',
          url: 'fwgz'
        }],
        jdgl: [{text: '定位查询', url: 'jdgl'}, {text: '短信平台', url: 'jdgl'}],
        yjzh: [{text: '远程督查', url: 'yjzh'}, {text: '移动指挥', url: 'yjzh'}],
        tjfx: [{text: '数量统计', url: 'tjfx'}, {text: '类别统计', url: 'tjfx'}],
        bgzl: [{text: '政策法规', url: 'bgzl'}, {text: '工作通知', url: 'bgzl'}, {text: '工作动态', url: 'bgzl'}, {
          text: '通讯录',
          url: 'bgzl'
        }, {text: '工作检查', url: 'bgzl'}],
        xtgl: [{text: '机构管理', url: 'xtgl'}, {text: '用户管理', url: 'xtgl'}, {text: '行政区域管理', url: 'xtgl'}, {
          text: '同步日志',
          url: 'xtgl'
        }, {text: '日志管理', url: 'xtgl'}, {text: '智能问答', url: 'xtgl'}],
      },
      menulist: [{text: '首页', url: 'index'}],
      scurrpage: '首页',
      username: '',
      password: '',
      page: ''
    }
  },
  created() {
    let user = sessionStorage.getItem("user")
    if (user) {
      this.page = 'index'
    } else {
      this.page = 'login'
    }


  },
  watch: {},
  mounted() {
    let width = document.body.clientWidth
    if (width == 1280) {
      document.querySelector(".login-container").style = "margin-left:calc((100vw - 1280px)/2)"
    }
    document.title = "湖南省智慧儿童一体化平台"
  },
  methods: {
    enterPage(currpage, scurrpage) {
      this.currpage = currpage
      this.scurrpage = scurrpage
      this.menulist = this.smenus[currpage]
    },
    login() {
      if (this.username == 'admin' && this.password == '123456') {
        sessionStorage.setItem("user", {username: this.username, password: this.password})
        this.page = 'index'
      } else {
        alert("用户名和密码错误！")
      }
    },
    exit() {
      this.page = 'login'
      sessionStorage.removeItem("user")
      location.reload()
    }
  }
}
</script>

<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  margin: 0;
}

.topBar {
  width: 100vw;
  height: 70px;
  line-height: 70px;
  background-color: rgb(55, 186, 160);
  text-align: left;
}

.topBar span:first-child {
  margin-left: 50px;
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}

.topBar span:last-child {
  float: right;
  margin-right: 80px;

}

.centerBar {
  width: 100vw;
  height: calc(100vh - 85px);
  margin-top: 15px;
  display: flex;
}

.leftBar {
  background-color: rgb(201, 236, 229);
  width: 250px;
  height: calc(100% - 70px);
  padding-top: 70px;
}

.menu {
  display: flex;
  height: 50px;
  /*background-color: #fff;*/
  width: 100%;

}

.contentBar {
  margin-left: 20px;
  width: calc(100vw - 290px);
  height: 100%;
}

.menuBar {
  height: 70px;
  line-height: 70px;
  width: 100%;
}

.menuBar ul {
  list-style: none;
  padding: 0;
  margin: 0px;
}

.menuBar ul li {
  float: left;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  width: 12.5%;
  min-width: 130px;
  cursor: pointer;
}

.active {
  color: #fff;
  background-color: rgb(55, 186, 160);
}

.beijing {
  height: calc(100% - 140px);
  width: 100%;
}

.beijingtu {
  height: 100%;
  width: 100%;
}

.menu UL {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu ul li {
  height: 50px;
  width: 100%;
}

.mactive {
  background-color: #fff;
}

.mactive .menu1 {
  background-color: rgb(55, 186, 160);
}

.container2 {
  width: 100vw;
  height: 100vh;
  background: url("../assets/login-beijingtu.jpg") repeat;
  background-size: 100% 100%;
  margin: 0;
  min-width: 1480px;
}

.login-container {
  width: 60vw;
  height: 50vh;
  background: url("../assets/login-bjt.png") repeat;
  background-size: 100% 100%;
  margin-left: 20vw;
  margin-top: calc((100vh - 50vh) / 3);
  position: absolute;
}

.login-form {
  position: absolute;
  width: 50%;
  height: 100%;
  float: right;
  right: 0px;
}

.login-logo {
  height: 10vh;
  width: 100%;
  margin-top: 5vh;
}

.login-username {
  height: 5vh;
  width: 100%;
  margin-top: 3vh;
  position: relative;
  text-align: left;
}

.login-username img {
  width: 25px;
  height: 2vh;
  position: absolute;
  margin-left: 23%;
  z-index: 999;
  margin-top: 1.5vh;
}

.login-username input {
  height: 5vh;
  line-height: 5vh;
  width: 60%;
  position: absolute;
  margin-left: 20%;
  z-index: 666;
  border-radius: 50px;
  background-color: rgb(248, 248, 248);
  font-size: 22px;
  border: 0px;
  padding-left: 60px;
}

.login-submit {
  width: 100%;
  margin-top: 5vh;
}

.login-submit button {
  background-color: rgb(31, 188, 255);
  color: #fff;
  border: 0px;
  border-radius: 50px;
  height: 5vh;
  line-height: 5vh;
  width: 70%;
  font-size: 16px;
  font-weight: 600;
  margin-left: 60px;
}
</style>
